<div class="content">
  <form [formGroup]="form" ngxsForm="profile.profileForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.FIRST_NAME' | translate }}</mat-label>
      <input matInput formControlName="firstName" required />
      <mat-error *ngIf="form.controls.firstName.invalid">{{ 'PROFILE.ERRORS.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.LAST_NAME' | translate }}</mat-label>
      <input matInput formControlName="lastName" required />
      <mat-error *ngIf="form.controls.lastName.invalid">{{ 'PROFILE.ERRORS.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.GENDER' | translate }}</mat-label>
      <mat-select formControlName="gender">
        <mat-option value="MALE">{{ 'PROFILE.GENDER_OPTIONS.MALE' | translate }}</mat-option>
        <mat-option value="FEMALE">{{ 'PROFILE.GENDER_OPTIONS.FEMALE' | translate }}</mat-option>
        <mat-option value="X">{{ 'PROFILE.GENDER_OPTIONS.X' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.ZIP_CODE' | translate }}</mat-label>
      <input matInput formControlName="zipCode" required />
      <mat-error *ngIf="form.controls.zipCode.invalid">{{ 'PROFILE.ERRORS.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.MUNICIPALITY' | translate }}</mat-label>
      <input matInput formControlName="city" required />
      <mat-error *ngIf="form.controls.city.invalid">{{ 'PROFILE.ERRORS.REQUIRED' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.YEAR_OF_BIRTH' | translate }}</mat-label>
      <input matInput formControlName="yearOfBirth" (focus)="picker.open()" />
      <input style="display: none;" matInput [matDatepicker]="picker" formControlName="hiddenYearOfBirth" />
      <mat-datepicker
        touchUi
        #picker
        startView="multi-year"
        (yearSelected)="closePicker($event, picker)"
      ></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.EMAIL' | translate }}</mat-label>
      <input matInput formControlName="email" required />
      <mat-error *ngIf="form.controls.email.errors?.required">{{ 'PROFILE.ERRORS.REQUIRED' | translate }}</mat-error>
      <mat-error *ngIf="form.controls.email.errors?.email">{{ 'PROFILE.ERRORS.EMAIL_INVALID' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'PROFILE.PHONE' | translate }}</mat-label>
      <ngx-mat-intl-tel-input
        [onlyCountries]="['be', 'nl', 'fr', 'de', 'gb']"
        [enablePlaceholder]="true"
        formControlName="phone"
      ></ngx-mat-intl-tel-input>
    </mat-form-field>
  </form>
  <form class="checkboxes" [formGroup]="termsForm" ngxsForm="profile.termsForm">
    <div class="form-row">
      <mat-checkbox color="primary" formControlName="termsAccept">
        {{ 'PROFILE.I_ACCEPT' | translate }}
        <br />
        <a
          [class.mat-color-primary]="termsForm.controls.termsAccept.enabled"
          [class.mat-inactive]="termsForm.controls.termsAccept.disabled"
          href="assets/docs/privacybeleid.pdf"
          target="_blank"
          >{{ 'PROFILE.GENERAL_TERMS_AND_PRIVACY_POLICY' | translate }}
        </a>
      </mat-checkbox>
    </div>
    <!--    <div class="form-row">-->
    <!--      <mat-checkbox color="primary" formControlName="privacyTermsAccept">{{-->
    <!--        'PROFILE.ACCEPT_PRIVACY_TERMS' | translate-->
    <!--      }}</mat-checkbox>-->
    <!--    </div>-->

    <!--    <div class="form-row">-->
    <!--      <mat-checkbox color="primary" formControlName="termsAccept">{{-->
    <!--        'PROFILE.ACCEPT_GENERAL_TERMS' | translate-->
    <!--      }}</mat-checkbox>-->
    <!--    </div>-->
  </form>

  <button
    class="submit-button"
    mat-raised-button
    color="primary"
    (click)="saveProfile()"
    [disabled]="!(form.valid && !termsForm.invalid) || (form.pristine && termsForm.pristine)"
  >
    {{ 'COMMON.CONTINUE' | translate }}
  </button>

  <button *ngIf="profile.currentUserId" class="cancel-button" mat-raised-button (click)="cancel()">
    <span>{{ 'COMMON.CANCEL' | translate }}</span>
  </button>
</div>
