import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Computed, DataAction, StateRepository } from '@ngxs-labs/data/decorators';
import { NgxsImmutableDataRepository } from '@ngxs-labs/data/repositories';
import { State } from '@ngxs/store';
import { CognitoAccessToken, CognitoIdToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { produce } from 'immer';
import { interval, Observable, Subscription } from 'rxjs';
import { LocationState } from '../../location/store/location.state';
import { AppConfigState } from './app-config.state';

export interface AuthStateModel {
  signedIn: boolean;
  inProgress: boolean;
  accessToken: CognitoAccessToken;
  idToken: CognitoIdToken;
}

const POST_SIGN_IN_PATH = 'tvh_post_sign_in_path';

@StateRepository()
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    signedIn: false,
    inProgress: false,
    accessToken: undefined,
    idToken: undefined,
  },
})
@Injectable()
export class AuthState extends NgxsImmutableDataRepository<AuthStateModel> {
  refreshTrigger$: Observable<void>;

  private readonly refreshEmitter: EventEmitter<void> = new EventEmitter<void>();
  private refreshInterval: Subscription;

  constructor(
    private readonly config: AppConfigState,
    private readonly locationState: LocationState,
    private readonly router: Router
  ) {
    super();
    this.refreshTrigger$ = this.refreshEmitter.asObservable();
  }

  ngxsOnInit(): void {
    super.ngxsOnInit();

    Hub.listen('auth', (data) => {
      const event = data.payload.event;
      if (event === 'signOut') {
        console.warn('user signed out...');
        this.stopPeriodicRefresh();
      }
      if (event === 'signIn') {
        void this.router.navigateByUrl(localStorage.getItem(POST_SIGN_IN_PATH) ?? 'location');
        localStorage.removeItem(POST_SIGN_IN_PATH);
      }
      if (event === 'cognitoHostedUI') {
        localStorage.setItem('amplify-redirected-from-hosted-ui', 'false');
      }
    });
  }

  ngxsAfterBootstrap(): void {
    super.ngxsAfterBootstrap();
    this.locationState.loadLocations();
    this.checkActiveSession();
  }

  @Computed() get idToken(): CognitoIdToken {
    return this.snapshot.idToken;
  }

  @Computed() get userFirstName(): string {
    return this.idToken.payload.given_name;
  }

  @Computed() get userLastName(): string {
    return this.idToken.payload.family_name;
  }

  @Computed() get userEmailAddress(): string {
    return this.idToken.payload.email;
  }

  @Computed() get signedIn(): boolean {
    return this.snapshot.signedIn;
  }

  @Computed() get isAdmin(): boolean {
    return this.snapshot.accessToken?.payload['cognito:groups'].includes('admins');
  }

  @Computed() get isInProgress(): boolean {
    return this.snapshot.inProgress;
  }

  @Computed() get isManager(): boolean {
    return this.snapshot.accessToken?.payload['cognito:groups'].includes('managers') || this.isAdmin;
  }

  @DataAction() signInFacebook(): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.inProgress = true;
      })
    );
    this.signIn(CognitoHostedUIIdentityProvider.Facebook);
  }

  @DataAction() signInGoogle(): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.inProgress = true;
      })
    );
    this.signIn(CognitoHostedUIIdentityProvider.Google);
  }

  @DataAction() updateTokens(session: CognitoUserSession): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.accessToken = session.getAccessToken();
        draft.idToken = session.getIdToken();
        draft.signedIn = true;
      })
    );
  }

  signOut = async (): Promise<void> => {
    try {
      // needed to provide a clean localStorage environment for re-login!
      localStorage.removeItem('amplify-signin-with-hostedUI');

      return Auth.signOut();
    } catch (error) {
      console.error('error signing out: ', error);
    }
  };

  triggerRefresh = () => {
    this.refreshEmitter.emit();
  };

  private readonly checkActiveSession = (): void => {
    void Auth.currentSession()
      .then((session) => {
        if (!!session) {
          this.startPeriodicRefresh();
        }
      })
      .catch(() => {
        // no-op
      });
  };

  private readonly startPeriodicRefresh = (): void => {
    if (this.refreshInterval) {
      this.refreshInterval.unsubscribe();
    }
    this.refreshInterval = interval(this.config.refreshInterval).subscribe(() => this.refreshEmitter.emit());
  };

  private readonly stopPeriodicRefresh = (): void => {
    if (this.refreshInterval) {
      this.refreshInterval.unsubscribe();
    }
  };

  private readonly signIn = (provider: CognitoHostedUIIdentityProvider): void => {
    localStorage.setItem(POST_SIGN_IN_PATH, location.hash.replace('#/', ''));
    void Auth.federatedSignIn({ provider });
  };
}
