import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigState } from '../../../../shared/store/app-config.state';
import { Machine } from '../models/machine.model';
import { StartMachine } from '../models/start-machine';

@Injectable({
  providedIn: 'root',
})
export class MachineService {
  constructor(private readonly config: AppConfigState, private readonly http: HttpClient) {}

  getMachineById(locationId: number, machineNumber: number): Observable<Machine> {
    return this.http.get<Machine>(`${this.config.apiPath}/locations/${locationId}/machine/${machineNumber}`);
  }

  startMachine(locationId: number, machine: StartMachine): Observable<Machine> {
    return this.http.put<Machine>(
      `${this.config.apiPath}/locations/${locationId}/machine/${machine.number}/start`,
      machine
    );
  }
}
