import { Injectable } from '@angular/core';
import { Computed, DataAction, Payload, StateRepository } from '@ngxs-labs/data/decorators';
import { NgxsImmutableDataRepository } from '@ngxs-labs/data/repositories';
import { State } from '@ngxs/store';
import { notUndefined } from '@ppwcode/js-ts-oddsandends/lib/conditional-assert';
import produce from 'immer';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ProfileState } from '../../../../profile/store/profile.state';
import { NavigateService } from '../../../../shared/services/navigate.service';
import { AuthState } from '../../../../shared/store/auth.state';
import { TvhMachineType } from '../../machine/constants/machine-type.constant';
import { Machine } from '../../machine/models/machine.model';
import { LocationDetailService } from '../services/location-detail.service';

interface LocationDetailModel {
  locationId?: number;
  machines: Array<Machine>;
  availableMachineTypes: Array<TvhMachineType>;
}

@StateRepository()
@State<LocationDetailModel>({
  name: 'locationDetail',
  defaults: {
    locationId: undefined,
    machines: [],
    availableMachineTypes: [],
  },
})
@Injectable()
export class LocationDetailState extends NgxsImmutableDataRepository<LocationDetailModel> {
  private locationRefresh: Subscription;
  private readonly restrictedMachineTypes: Array<TvhMachineType> = [TvhMachineType.DOOR, TvhMachineType.LIGHT];

  constructor(
    private readonly authState: AuthState,
    private readonly profile: ProfileState,
    private readonly navigate: NavigateService,
    private readonly service: LocationDetailService
  ) {
    super();
  }

  @Computed() get locationId(): number | undefined {
    return this.snapshot.locationId;
  }

  @Computed() get machines(): Array<Machine> {
    return [...this.snapshot.machines];
  }

  @Computed() get myMachines(): Array<Machine> {
    return [...this.snapshot.machines.filter((m: Machine) => m.customerId === this.profile.currentUserId)];
  }

  @Computed() get availableMachineTypes(): Array<TvhMachineType> {
    return [...this.snapshot.availableMachineTypes];
  }

  @DataAction() addCredit(): void {
    this.navigate.toAddCredit();
  }

  @DataAction() cancelActivityStart(): void {
    this.navigate.toLocationById(this.snapshot.locationId);
  }

  @DataAction() startActivity(): void {
    this.navigate.toStartActivity(this.snapshot.locationId);
  }

  @DataAction() showLocationMachines(@Payload('type') type: TvhMachineType): void {
    this.navigate.toLocationMachines(notUndefined(this.snapshot.locationId), type);
  }

  @DataAction() setMachinesInfoForLocation(
    @Payload('id') id: number,
    @Payload('machines') machines: Array<Machine>
  ): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.locationId = id;
        draft.machines = machines;
        draft.availableMachineTypes = [];
        for (const type of Object.keys(TvhMachineType)) {
          if (
            !this.restrictedMachineTypes.includes(type as TvhMachineType) &&
            !!machines.find((m: Machine) => m.type === type)
          ) {
            draft.availableMachineTypes.push(type as TvhMachineType);
          }
        }
      })
    );
  }

  ngxsAfterBootstrap(): void {
    super.ngxsAfterBootstrap();

    if (this.locationRefresh) {
      this.locationRefresh.unsubscribe();
    }
    this.locationRefresh = this.authState.refreshTrigger$
      .pipe(
        filter(() => !!this.locationId),
        switchMap(() => this.service.getMachinesInfoForLocation(this.locationId, true))
      )
      .subscribe((machines: Array<Machine>) => {
        this.setMachinesInfoForLocation(this.locationId, machines);
      });
  }
}
