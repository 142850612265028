import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { AddCreditComponent } from './payment/components/add-credit/add-credit.component';
import { PaymentErrorComponent } from './payment/components/payment-error/payment-error.component';
import { PaymentLandingComponent } from './payment/components/payment-landing/payment-landing.component';
import { PaymentSuccessComponent } from './payment/components/payment-success/payment-success.component';
import { PaymentResolverGuard } from './payment/guards/payment-resolver.guard';
import { ProfileCompletedGuard } from './profile/guards/profile-completed.guard';
import { ProfileComponent } from './profile/profile.component';
import { ManagerRoleGuard } from './shared/guards/manager-role.guard';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canDeactivate: [ProfileCompletedGuard],
    data: { title: 'PROFILE.TITLE' },
  },
  {
    path: 'add-credit',
    component: AddCreditComponent,
    data: { title: 'ADD_CREDIT.TITLE' },
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: { title: 'CONTACT.TITLE' },
  },
  {
    path: 'afterpayment/:paymentId',
    component: PaymentLandingComponent,
    canActivate: [PaymentResolverGuard],
    data: { title: 'LANDING.PAYMENT_LANDING' },
    children: [
      {
        path: 'success',
        component: PaymentSuccessComponent,
        data: { title: 'LANDING.PAYMENT_SUCCESS' },
      },
      {
        path: 'error',
        component: PaymentErrorComponent,
        data: { title: 'LANDING.PAYMENT_ERROR' },
      },
    ],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'location',
    // tslint:disable-next-line:no-implicit-dependencies
    loadChildren: () => import('src/app/location/location.module').then((m) => m.LocationModule),
  },
  {
    path: 'mgmt',
    // tslint:disable-next-line:no-implicit-dependencies
    loadChildren: () => import('src/app/settings/settings.module').then((m) => m.SettingsModule),
    canLoad: [ManagerRoleGuard],
  },
  {
    path: '**',
    redirectTo: 'location',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected', useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
