import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { NavigateService } from '../services/navigate.service';
import { AuthState } from '../store/auth.state';

@Injectable({
  providedIn: 'root',
})
export class ManagerRoleGuard implements CanLoad {
  constructor(protected readonly auth: AuthState, private readonly navigate: NavigateService) {}

  canLoad(): boolean {
    if (this.hasRole) {
      return true;
    }

    this.navigate.toLocationSelect();

    return false;
  }

  protected get hasRole(): boolean {
    return this.auth.isManager;
  }
}
