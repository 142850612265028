import { Component } from '@angular/core';
import { LocationState } from '../../../location/store/location.state';
import { ProfileState } from '../../../profile/store/profile.state';

@Component({
  selector: 'tvh-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss'],
})
export class PaymentErrorComponent {
  constructor(readonly profile: ProfileState, private readonly location: LocationState) {}

  continue = () => {
    this.location.addCredit();
  };

  cancel = () => {
    this.location.toHome();
  };
}
