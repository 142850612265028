import { Observable, OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export const truthyFilter = <T>(): OperatorFunction<T | null | undefined, T> => (
  source$: Observable<T | null | undefined>
): Observable<T> =>
  source$.pipe(
    filter<T | null | undefined>(Boolean),
    map((res: T | null | undefined) => res)
  );
