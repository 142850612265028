import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Computed, DataAction, Payload, StateRepository } from '@ngxs-labs/data/decorators';
import { NgxsImmutableDataRepository } from '@ngxs-labs/data/repositories';
import { State } from '@ngxs/store';
import produce, { Immutable } from 'immer';
import { ProfileState } from '../../../../profile/store/profile.state';
import { DEFAULT_TIME_UNITS_FORM, TIME_UNITS } from '../../../../shared/constants/time-units.constant';
import { NavigateService } from '../../../../shared/services/navigate.service';
import { AuthState } from '../../../../shared/store/auth.state';
import { Machine } from '../models/machine.model';
import { StartMachine } from '../models/start-machine';
import { MachineUtilsService } from '../services/machine-utils.service';
import { MachineService } from '../services/machine.service';

interface MachineStateModel {
  machine: Machine;
  canCancel: boolean;
  timeUnits: Array<number>;
  timeUnitsForm: { model: { timeUnits: number }; dirty: boolean; status: string; errors: { [key: string]: unknown } };
  isFlowInProgress: boolean;
  isPending: boolean;
  isTimeAdded: boolean;
}

@StateRepository()
@State<MachineStateModel>({
  name: 'machine',
  defaults: {
    canCancel: true,
    machine: undefined,
    timeUnits: TIME_UNITS,
    timeUnitsForm: DEFAULT_TIME_UNITS_FORM,
    isFlowInProgress: false,
    isPending: false,
    isTimeAdded: false,
  },
})
@Injectable()
export class MachineState extends NgxsImmutableDataRepository<MachineStateModel> {
  constructor(
    private readonly auth: AuthState,
    private readonly machineSvc: MachineService,
    private readonly navigate: NavigateService,
    private readonly profile: ProfileState
  ) {
    super();
  }

  @Computed() get canCancel(): boolean {
    return this.snapshot.canCancel;
  }

  @Computed() get canAddTime(): boolean {
    return (this.isDryer || this.isIroningRoll) && this.isOurs;
  }

  @Computed() get machine(): Machine {
    return this.snapshot.machine;
  }

  @Computed() get priceOfAction(): number {
    return this.snapshot.timeUnitsForm?.model?.timeUnits * this.machine.pricePerUnit;
  }

  @Computed() get timeMultiples(): Immutable<Array<number>> {
    return this.snapshot.timeUnits;
  }

  @Computed() get timeUnitSize(): number {
    return this.snapshot.machine.unit;
  }

  @Computed() get isAvailable(): boolean {
    return MachineUtilsService.isAvailable(this.machine);
  }

  @Computed() get isBusy(): boolean {
    return MachineUtilsService.isBusy(this.machine);
  }

  @Computed() get isDryer(): boolean {
    return MachineUtilsService.isDryer(this.machine);
  }

  @Computed() get isIroningRoll(): boolean {
    return MachineUtilsService.isIroningRoll(this.machine);
  }

  @Computed() get isOurs(): boolean {
    return MachineUtilsService.isOurs(this.machine, this.profile.currentUserId);
  }

  @Computed() get isFlowInProgress(): boolean {
    return this.snapshot.isFlowInProgress;
  }

  @Computed() get isPending(): boolean {
    return this.snapshot.isPending;
  }

  @Computed() get isMachineStartDisabled(): boolean {
    return this.isPending || !this.isAvailable;
  }

  @Computed() get isTimeAdded(): boolean {
    return this.snapshot.isTimeAdded;
  }

  @Computed() get isWasher(): boolean {
    return MachineUtilsService.isWashingMachine(this.machine);
  }

  @DataAction() setCanCancel(@Payload('canCancel') canCancel: boolean): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.canCancel = canCancel;
      })
    );
  }

  @DataAction() markFlowCompleted(): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.isFlowInProgress = false;
      })
    );
  }

  @DataAction() markFlowStarted(): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.isFlowInProgress = true;
      })
    );
  }

  @DataAction() startFlow(@Payload('machine') machine: Machine): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.isFlowInProgress = true;
      })
    );
    if (
      machine &&
      (MachineUtilsService.isAvailable(machine) || MachineUtilsService.isOurs(machine, this.profile.currentUserId))
    ) {
      // navigate to detail page
      if (machine.multiProgram) {
        this.navigate.toMachineProgramSelect(machine.locationId, machine.number);
      } else {
        this.navigate.toMachineDetail(machine.locationId, machine.number);
      }
    }
  }

  @DataAction() startAddTimeFlow(@Payload('machine') machine: Machine): void {
    if (MachineUtilsService.isDryer(machine) || MachineUtilsService.isIroningRoll(machine)) {
      this.ctx.setState(
        produce(this.ctx.getState(), (draft) => {
          draft.isFlowInProgress = true;
        })
      );
      this.navigate.toMachineAddTime(machine.locationId, machine.number);
    }
  }

  @DataAction() cancelFlow(): void {
    this.clearCurrentFlow();

    // if the machine's customerId matches the currently signed in user, we know the user is trying to add
    // credit to one of his/her running machines, and should return to the location home on cancel
    if (this.machine.customerId === this.profile.currentUserId) {
      this.navigate.toLocationById(this.machine.locationId);

      return;
    }
    // otherwise, return to the machine overview on cancel
    this.navigate.toLocationMachines(this.machine.locationId, this.machine.type);
  }

  @DataAction() completeFlow(): void {
    this.clearCurrentFlow();
    this.navigate.toLocationById(this.machine.locationId);
  }

  @DataAction() setMachine(@Payload('machine') machine: Machine): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.machine = machine;
      })
    );
  }

  startMachine(): void {
    this.setState(
      produce(this.getState(), (draft) => {
        draft.isPending = true;
      })
    );

    const timeAdded = this.isBusy;

    const startMachine: StartMachine = {
      number: this.machine.number,
      pricePerUnit: this.machine.pricePerUnit,
      unit: this.machine.unit,
      amountOfUnits: this.snapshot.timeUnitsForm.model.timeUnits,
      customerId: this.profile.currentUserId,
    };

    this.machineSvc.startMachine(this.machine.locationId, startMachine).subscribe(
      (machine: Machine): void => {
        this.auth.triggerRefresh();
        this.setState(
          produce(this.getState(), (draft) => {
            draft.machine = machine;
            draft.isTimeAdded = timeAdded;
            draft.isPending = false;
          })
        );
        this.navigate.toMachineStartSuccess(this.machine.locationId, this.machine.number);
      },
      (err: HttpErrorResponse) => {
        this.setState(
          produce(this.getState(), (draft) => {
            draft.isPending = false;
          })
        );
        if (err.status === 402) {
          this.navigate.toMachineStartInsufficientFunds(this.machine.locationId, this.machine.number);

          return;
        }
        this.navigate.toMachineStartFailure(this.machine.locationId, this.machine.number);
      }
    );
  }

  private readonly clearCurrentFlow = () => {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.timeUnitsForm.model.timeUnits = 1;
        draft.isFlowInProgress = false;
      })
    );
  };
}
