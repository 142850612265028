import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Computed, DataAction, Payload, Persistence, StateRepository } from '@ngxs-labs/data/decorators';
import { NgxsImmutableDataRepository } from '@ngxs-labs/data/repositories';
import { State } from '@ngxs/store';
import produce from 'immer';
import { TvhLanguage } from '../constants/language.constant';

@Persistence()
@StateRepository()
@State<{ lang: TvhLanguage }>({
  name: 'lang',
  defaults: {
    lang: undefined,
  },
})
@Injectable()
export class LangState extends NgxsImmutableDataRepository<{ lang: TvhLanguage }> {
  constructor(private readonly translate: TranslateService) {
    super();
  }

  ngxsAfterBootstrap(): void {
    super.ngxsAfterBootstrap();
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (this.lang) {
      this.translate.use(this.lang);
    }
  }

  @Computed() get lang(): TvhLanguage {
    return this.snapshot.lang;
  }

  @DataAction() clearLangSelection(): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.lang = undefined;
      })
    );
  }

  @DataAction() setAppLanguage(@Payload('lang') lang: TvhLanguage): void {
    this.translate.use(lang);
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.lang = lang;
      })
    );
  }
}
