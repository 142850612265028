import { Observable, OperatorFunction } from 'rxjs';
import { first, map } from 'rxjs/operators';

export const truthyFirst = <T>(): OperatorFunction<T | null | undefined, T> => (
  source$: Observable<T | null | undefined>
): Observable<T> =>
  source$.pipe(
    first<T | null | undefined>(Boolean),
    map((res: T | null | undefined) => res)
  );
