import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Computed, DataAction, Payload, StateRepository } from '@ngxs-labs/data/decorators';
import { NgxsImmutableDataRepository } from '@ngxs-labs/data/repositories';
import { SetFormDisabled, SetFormEnabled, UpdateFormValue } from '@ngxs/form-plugin';
import { State, Store } from '@ngxs/store';
import produce from 'immer';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { NavigateService } from '../../shared/services/navigate.service';
import { AppConfigState } from '../../shared/store/app-config.state';
import { AuthState, AuthStateModel } from '../../shared/store/auth.state';
import { TvhUserBalance } from '../models/balance';
import { TvhUserProfile } from '../models/profile';
import { ProfileService } from '../services/profile.service';

export interface ProfileStateModel {
  current: TvhUserProfile;
  profileForm: {
    model: {
      firstName: string;
      lastName: string;
      gender: string;
      zipCode: string;
      city: string;
      yearOfBirth: number;
      hiddenYearOfBirth: string;
      email: string;
      phone: string;
    };
    dirty: boolean;
    status: string;
    errors: { [key: string]: unknown };
  };
  termsForm: {
    model: {
      privacyTermsAccept: boolean;
      termsAccept: boolean;
    };
    dirty: boolean;
    status: string;
    errors: { [key: string]: unknown };
  };
}

@StateRepository()
@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    current: undefined,
    profileForm: {
      model: {
        firstName: '',
        lastName: '',
        gender: '',
        zipCode: '',
        city: '',
        yearOfBirth: undefined,
        hiddenYearOfBirth: '',
        email: '',
        phone: '',
      },
      dirty: false,
      status: '',
      errors: {},
    },
    termsForm: {
      model: {
        privacyTermsAccept: true,
        termsAccept: false,
      },
      dirty: false,
      status: '',
      errors: {},
    },
  },
})
@Injectable()
export class ProfileState extends NgxsImmutableDataRepository<ProfileStateModel> {
  private profileRefresh: Subscription;

  constructor(
    private readonly auth: AuthState,
    private readonly config: AppConfigState,
    private readonly navigate: NavigateService,
    private readonly profileSvc: ProfileService,
    private readonly store: Store,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  ngxsAfterBootstrap(): void {
    super.ngxsAfterBootstrap();
    this.auth.state$
      .pipe(
        map((auth: AuthStateModel) => auth.idToken?.payload?.sub),
        distinctUntilChanged()
      )
      .subscribe((sub) => {
        if (sub) {
          this.getProfile(sub);

          // Periodic refresh
          if (this.profileRefresh) {
            this.profileRefresh.unsubscribe();
          }
          this.profileRefresh = this.auth.refreshTrigger$.subscribe(() => {
            this.refreshBalance();
          });
        } else {
          this.setState(produce(this.getState(), (draft) => (draft.current = undefined)));
        }
      });
  }

  @Computed() get current(): TvhUserProfile {
    return this.snapshot.current;
  }

  @Computed() get currentUserId(): number {
    return this.current?.id;
  }

  @Computed() get balance(): number {
    return this.current?.balance + this.current?.reservedAmount ?? -1;
  }

  @DataAction() getProfile(@Payload('sub') sub: string): void {
    this.profileSvc
      .getProfile(sub)
      .pipe(
        catchError((e) => {
          if (e.status === 404) {
            // Redirect user to profile page to complete new profile
            this.setState(
              produce(this.getState(), (draft) => {
                draft.profileForm.model.firstName = this.auth.userFirstName;
                draft.profileForm.model.lastName = this.auth.userLastName;
                draft.profileForm.model.email = this.auth.userEmailAddress;
              })
            );
            this.toastr.info(
              this.translate.instant('PROFILE.MSG.PLEASE_COMPLETE_PROFILE'),
              this.translate.instant('PROFILE.MSG.WELCOME'),
              { timeOut: 10000 }
            );
            this.navigate.toProfile();
          }

          return EMPTY;
        })
      )
      .subscribe((profile: TvhUserProfile) => {
        this.setState(
          produce(this.getState(), (draft) => {
            draft.current = profile;
          })
        );
        this.updateForm();
      });
  }

  @DataAction() refreshBalance(): void {
    this.profileSvc.getBalance(this.currentUserId).subscribe((balance: TvhUserBalance) => {
      this.setState(
        produce(this.getState(), (draft) => {
          draft.current.balance = balance.balance;
          draft.current.reservedAmount = balance.reservedAmount;
        })
      );
    });
  }

  @DataAction() updateProfile(@Payload('profile') profile: TvhUserProfile): void {
    this.profileSvc.updateProfile(profile).subscribe((updated: TvhUserProfile) => {
      this.setState(
        produce(this.getState(), (draft) => {
          draft.current = updated;
        })
      );
      this.updateForm();
      // navigate to location select
      this.navigate.toLocationSelect();
    });
  }

  private updateForm(): void {
    this.store.dispatch(
      new UpdateFormValue({
        value: {
          firstName: this.current.firstName,
          lastName: this.current.lastName,
          gender: this.current.gender,
          zipCode: this.current.zipCode,
          city: this.current.city,
          yearOfBirth: this.current.yearOfBirth,
          hiddenYearOfBirth: new Date(this.current.yearOfBirth ?? 2000, 1, 10).toISOString(),
          email: this.current.email,
          phone: this.current.phone,
        },
        path: 'profile.profileForm',
      })
    );
    this.store.dispatch(
      new UpdateFormValue({
        value: {
          termsAccept: this.current.termsAccepted,
          privacyTermsAccept: this.current.termsAccepted,
        },
        path: 'profile.termsForm',
      })
    );
    this.store.dispatch(
      this.current.id ? new SetFormDisabled('profile.termsForm') : new SetFormEnabled('profile.termsForm')
    );
  }
}
