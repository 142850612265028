import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { PaymentLandingState } from '../store/payment-landing.state';

@Injectable({
  providedIn: 'root',
})
export class PaymentResolverGuard implements CanActivate {
  constructor(private readonly paymentLanding: PaymentLandingState) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const idParam = route.paramMap.get('paymentId');

    if (idParam) {
      const id = parseInt(idParam, 10);
      this.paymentLanding.setPaymentId(id);

      return true;
    }

    return false;
  }
}
