import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Computed, DataAction, Payload, StateRepository } from '@ngxs-labs/data/decorators';
import { NgxsImmutableDataRepository } from '@ngxs-labs/data/repositories';
import { State, Store } from '@ngxs/store';
import { createLocation, distanceTo, Location } from 'geolocation-utils';
import { produce } from 'immer';
import { merge, of } from 'rxjs';
import { filter, finalize, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { truthyFilter } from '../../shared/operators/truthy-filter';
import { truthyFirst } from '../../shared/operators/truthy-first';
import { NavigateService } from '../../shared/services/navigate.service';
import { AppConfigState } from '../../shared/store/app-config.state';
import { TvhEnrichedLocation, TvhLocation } from '../models/tvh-location';

interface LocationStateModel {
  location: TvhEnrichedLocation;
  sorted: Array<TvhEnrichedLocation>;
  allLocations: Array<TvhLocation>;
  allLocationsLoading: boolean;
  closest: TvhEnrichedLocation;
  currentUserLocation: Location;
  enrichedLocations: Array<TvhEnrichedLocation>;
  locationOverride: TvhEnrichedLocation;
  locationConfirmed: boolean;
}

// @Persistence()
@StateRepository()
@State<LocationStateModel>({
  name: 'location',
  defaults: {
    location: undefined,
    sorted: undefined,
    allLocations: undefined,
    allLocationsLoading: false,
    closest: undefined,
    currentUserLocation: undefined,
    enrichedLocations: undefined,
    locationOverride: undefined,
    locationConfirmed: false,
  },
})
@Injectable()
export class LocationState extends NgxsImmutableDataRepository<LocationStateModel> {
  @Computed() get closestLocation(): TvhEnrichedLocation {
    return this.snapshot.closest;
  }

  @Computed() get location(): TvhEnrichedLocation {
    // location is either the closest location, or the overridden location
    return this.snapshot.locationOverride ?? this.snapshot.closest;
  }

  @Computed() get locationName(): string {
    // location is either the closest location, or the overridden location
    return this.location?.name ?? '';
  }

  @Computed() get isLocationOverridden(): boolean {
    return !!this.snapshot.locationOverride;
  }

  @Computed() get isLocationConfirmed(): boolean {
    return this.snapshot.locationConfirmed;
  }

  @Computed() get all(): ReadonlyArray<TvhLocation> {
    return this.snapshot.allLocations;
  }

  @Computed() get sortedLocations(): ReadonlyArray<TvhEnrichedLocation> {
    return this.snapshot.sorted;
  }

  constructor(
    private readonly config: AppConfigState,
    private readonly http: HttpClient,
    private readonly navigate: NavigateService,
    private readonly store: Store
  ) {
    super();
  }

  ngxsAfterBootstrap(): void {
    super.ngxsAfterBootstrap();
    // update calculated distances if allLocations or currentUserLocation changes
    merge(
      this.store.select((state) => state.location.allLocations),
      this.store.select((state) => state.location.currentUserLocation)
    )
      .pipe(
        switchMap(() => of({ locations: this.allLocations, userLocation: this.currentUserLocation })),
        filter(({ locations }) => !!locations)
      )
      .subscribe(({ locations, userLocation }) => {
        this.setEnrichedLocations(this.enrich([...locations], userLocation));
      });

    // update sorted list when enrichedLocations change
    this.store
      .select((state) => state.location.enrichedLocations)
      .pipe(truthyFilter())
      .subscribe((locations: Array<TvhEnrichedLocation>) => {
        this.setSortedLocations([...locations].sort(this.distanceCompareFn));
      });

    // closest location is first element of the sorted array, if available
    this.store
      .select((state) => state.location.sorted)
      .pipe(
        truthyFilter(),
        withLatestFrom(() => this.getState()),
        filter((state: LocationStateModel) => !state.locationOverride && !!state.currentUserLocation),
        map((state: LocationStateModel) => state.sorted)
      )
      .subscribe((sorted: Array<TvhEnrichedLocation>) => {
        this.setClosestLocation(sorted[0]);
      });
  }

  @DataAction() addCredit(): void {
    this.navigate.toAddCredit();
  }

  @DataAction() confirmLocation(): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.locationConfirmed = true;
      })
    );
    this.navigate.toLocationById(this.location.id);
  }

  @DataAction() setUserLocation(@Payload('geolocation') geolocation: GeolocationPosition): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.currentUserLocation = createLocation(
          geolocation.coords.latitude,
          geolocation.coords.longitude,
          'LatitudeLongitude'
        );
      })
    );
  }

  @DataAction() overrideLocation(@Payload('location') location: TvhEnrichedLocation): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.locationOverride = location;
      })
    );
  }

  @DataAction() overrideLocationById(@Payload('locationId') locationId: number): void {
    this.state$
      .pipe(
        map((state) => state.enrichedLocations),
        truthyFirst()
      )
      .subscribe((enrichedLocations) => {
        this.setState(
          produce(this.getState(), (draft) => {
            draft.locationOverride = enrichedLocations.find((l: TvhEnrichedLocation) => l.id === locationId);
          })
        );
      });
  }

  @DataAction() loadLocations(): void {
    if (!this.getState().allLocationsLoading) {
      this.ctx.setState(
        produce(this.ctx.getState(), (draft) => {
          draft.allLocationsLoading = true;
        })
      );
      this.http
        .get<Array<TvhLocation>>(`${this.config.apiPath}/locations`)
        .pipe(
          finalize(() => {
            this.setState(
              produce(this.getState(), (draft) => {
                draft.allLocationsLoading = false;
              })
            );
          })
        )
        .subscribe((all) => {
          this.setLocations(all);
        });
    }
  }

  toHome = (): void => {
    if (this.location?.id && this.isLocationConfirmed) {
      this.navigate.toLocationById(this.location.id);
    } else {
      this.navigate.toLocationSelect();
    }
  };

  @Computed() private get allLocations(): ReadonlyArray<TvhLocation> {
    return this.snapshot.allLocations;
  }

  @Computed() private get currentUserLocation(): Location {
    return this.snapshot.currentUserLocation as Location;
  }

  @DataAction() private setClosestLocation(@Payload('closest') closest: TvhEnrichedLocation): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.closest = closest;
      })
    );
  }

  @DataAction() private setEnrichedLocations(@Payload('locations') locations: Array<TvhEnrichedLocation>): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.enrichedLocations = locations;
      })
    );
  }

  @DataAction() private setLocations(@Payload('locations') locations: Array<TvhLocation>): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.allLocations = locations;
      })
    );
  }

  @DataAction() private setSortedLocations(@Payload('locations') locations: Array<TvhEnrichedLocation>): void {
    this.ctx.setState(
      produce(this.ctx.getState(), (draft) => {
        draft.sorted = locations;
      })
    );
  }

  private readonly enrich = (
    locations: Array<TvhLocation>,
    userLocation: Location | undefined
  ): Array<TvhEnrichedLocation> => {
    const enriched = [];
    for (const loc of locations) {
      enriched.push({ ...loc, distanceToUser: userLocation ? distanceTo(loc.geo, userLocation) : 0 });
    }

    return enriched;
  };

  private readonly distanceCompareFn = (a: TvhEnrichedLocation, b: TvhEnrichedLocation): number => {
    if (a.distanceToUser && b.distanceToUser) {
      if (a.distanceToUser === b.distanceToUser) {
        return 0;
      }

      return a.distanceToUser < b.distanceToUser ? -1 : 1;
    }

    return a.name.localeCompare(b.name);
  };
}
