import { Injectable } from '@angular/core';
import { DataAction, Payload, StateRepository } from '@ngxs-labs/data/decorators';
import { NgxsImmutableDataRepository } from '@ngxs-labs/data/repositories';
import { State } from '@ngxs/store';
import { PaymentService } from '../services/payment.service';

export interface PaymentStateModel {
  amount: number;
}

@StateRepository()
@State<PaymentStateModel>({
  name: 'payment',
  defaults: {
    amount: 0,
  },
})
@Injectable()
export class PaymentState extends NgxsImmutableDataRepository<PaymentStateModel> {
  constructor(private readonly paymentSvc: PaymentService) {
    super();
  }
  @DataAction() startPayment(@Payload('amount') amount: number): void {
    this.paymentSvc.addPayment(amount);
  }
}
