import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { TvhMachineType } from '../../location/modules/machine/constants/machine-type.constant';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {
  @Dispatch() toAddCredit = () => new Navigate(['add-credit']);

  @Dispatch() toContactForm = () => new Navigate(['contact']);

  @Dispatch() toLocationSelect = () => new Navigate(['location']);

  @Dispatch() toLocationById = (locationId: number | string) => new Navigate(['location', locationId]);

  @Dispatch() toLocationMachines = (locationId: number | string, type: TvhMachineType) =>
    new Navigate(['location', locationId, 'machine'], { mt: type });

  @Dispatch() toMachineAddTime = (locationId: number | string, machineId: number | string) =>
    new Navigate(['location', locationId, 'machine', machineId, 'add-time']);

  @Dispatch() toMachineDetail = (locationId: number | string, machineId: number | string) =>
    new Navigate(['location', locationId, 'machine', machineId]);

  @Dispatch() toMachineInUse = (locationId: number | string, machineId: number | string) =>
    new Navigate(['location', locationId, 'machine', machineId, 'in-use']);

  @Dispatch() toMachineProgramSelect = (locationId: number | string, machineId: number | string) =>
    new Navigate(['location', locationId, 'machine', machineId, 'program']);

  @Dispatch() toMachineStartFailure = (locationId: number | string, machineId: number | string) =>
    new Navigate(['location', locationId, 'machine', machineId, 'failed']);

  @Dispatch() toMachineStartInsufficientFunds = (locationId: number | string, machineId: number | string) =>
    new Navigate(['location', locationId, 'machine', machineId, 'insufficient']);

  @Dispatch() toMachineStartSuccess = (locationId: number | string, machineId: number | string) =>
    new Navigate(['location', locationId, 'machine', machineId, 'success']);

  @Dispatch() toProfile = () => new Navigate(['profile']);

  @Dispatch() toManagement = () => new Navigate(['mgmt']);

  @Dispatch() toAdminFinancialHistory = () => new Navigate(['mgmt', 'admin', 'financial']);

  @Dispatch() toMgmtMachineControl = () => new Navigate(['mgmt', 'machine-control']);

  @Dispatch() toAdminPaymentConfig = () => new Navigate(['mgmt', 'admin', 'config']);

  @Dispatch() toAdminRevenue = () => new Navigate(['mgmt', 'admin', 'revenue']);

  @Dispatch() toAdminTransactions = () => new Navigate(['mgmt', 'admin', 'transactions']);

  @Dispatch() toStartActivity = (locationId: number | string) => new Navigate(['location', locationId, 'start']);

  @Dispatch() toPaymentError = (paymentId: number | string) => new Navigate(['afterpayment', paymentId, 'error']);

  @Dispatch() toPaymentSuccess = (paymentId: number | string) => new Navigate(['afterpayment', paymentId, 'success']);
}
