import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { LocationDetailState } from '../../location/modules/location-detail/store/location-detail.state';
import { Machine } from '../../location/modules/machine/models/machine.model';
import { AppConfigState } from '../store/app-config.state';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private connection?: signalR.HubConnection;
  private lastLocationId: number;

  constructor(private readonly config: AppConfigState, private readonly locationDetail: LocationDetailState) {}

  initialize(): void {
    this.connect();
  }

  connect(): void {
    this.startConnection();
    this.addListeners();
  }

  registerLocation(locationId: number): void {
    this.lastLocationId = locationId;
    localStorage.setItem('location-id', `${locationId}`);

    void this.connection?.send('RegisterLocation', locationId);
  }

  private startConnection(): void {
    // initialize the connection to the hub
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.config.signalRPath)
      .withAutomaticReconnect()
      .build();

    this.connection
      .start()
      .then(() => {
        console.warn('connection started');
      })
      .catch((err) => console.error(`error connecting signal-r: ${err}`));
  }

  private addListeners(): void {
    this.connection?.on('AskLocation', () => {
      console.warn('Location is asked');

      if (!this.locationDetail.locationId) {
        const localId = localStorage.getItem('location-id');
        if (localId !== null) {
          this.lastLocationId = parseInt(localId, 10);
          this.registerLocation(this.lastLocationId);
        }
      } else {
        console.warn(this.locationDetail.locationId);
        if (this.locationDetail.locationId) {
          this.registerLocation(this.locationDetail.locationId);
        }
      }
    });

    this.connection?.on('MachineHasEnded', (machine: Machine) => {
      console.warn('Machine has ended.');
      console.warn(machine);
      this.locationDetail.setMachinesInfoForLocation(this.locationDetail.locationId, [machine]);
    });
  }
}
