import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { combineLatest } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { LocationState } from './location/store/location.state';
import { ProfileState } from './profile/store/profile.state';
import { SubSinkComponent } from './shared/components/sub-sink.component';
import { TvhLanguage } from './shared/constants/language.constant';
import { NavigateService } from './shared/services/navigate.service';
import { SignalRService } from './shared/services/signal-r.service';
import { AuthState } from './shared/store/auth.state';
import { LangState } from './shared/store/lang.state';
import { LoadingState } from './shared/store/loading.state';
import { TitleState } from './shared/store/title.state';

@Component({
  selector: 'tvh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends SubSinkComponent implements OnInit {
  constructor(
    readonly auth: AuthState,
    public langState: LangState,
    readonly loading: LoadingState,
    public locationState: LocationState,
    readonly profile: ProfileState,
    public titleState: TitleState,
    private readonly activatedRoute: ActivatedRoute,
    private readonly navigate: NavigateService,
    private readonly router: Router,
    private readonly signalRService: SignalRService,
    private readonly title: Title,
    private readonly translate: TranslateService,
    private readonly consent: NgcCookieConsentService,
    iconReg: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    super();
    // add machine icons to registry
    iconReg
      .addSvgIcon('car-wash', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/car-wash.svg'))
      .addSvgIcon(
        'shopping-basket',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/shopping-basket.svg')
      )
      .addSvgIcon('shopping-bag', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/shopping-bag.svg'))
      .addSvgIcon('bakery-dining', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/bakery-dining.svg'))
      .addSvgIcon('coffee', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/coffee.svg'))
      .addSvgIcon('local-bar', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/local-bar.svg'))
      .addSvgIcon(
        'local-activity',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/local-activity.svg')
      )
      .addSvgIcon(
        'cleaning-services',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/cleaning-services.svg')
      )
      .addSvgIcon('machine-door', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/door.svg'))
      .addSvgIcon('machine-dryer', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/dryer.svg'))
      .addSvgIcon('machine-ironing-roll', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/iron.svg'))
      .addSvgIcon('machine-light', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/light.svg'))
      .addSvgIcon(
        'machine-washer',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/machines/washing-machine.svg')
      );
  }

  ngOnInit(): void {
    this.signalRService.initialize();

    // set page title from router data variable
    this.subs.add(
      combineLatest([
        this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route: ActivatedRoute) => {
            while (route.firstChild) {
              // tslint:disable-next-line:no-parameter-reassignment
              route = route.firstChild;
            }

            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)
        ),
        this.translate.onLangChange,
      ]).subscribe(([event]) => {
        if (event.hasOwnProperty('title')) {
          this.titleState.setTitle(event.title);
        }
      }),
      this.translate.onLangChange.subscribe(() => {
        this.translate
          .get([
            'COOKIE.HEADER',
            'COOKIE.MESSAGE',
            'COOKIE.DISMISS',
            'COOKIE.ALLOW',
            'COOKIE.DENY',
            'COOKIE.LINK',
            'COOKIE.POLICY',
          ])
          .subscribe((data) => {
            this.consent.getConfig().content = this.consent.getConfig().content || {};
            this.consent.getConfig().content.header = data['COOKIE.HEADER'];
            this.consent.getConfig().content.message = data['COOKIE.MESSAGE'];
            this.consent.getConfig().content.dismiss = data['COOKIE.DISMISS'];
            this.consent.getConfig().content.allow = data['COOKIE.ALLOW'];
            this.consent.getConfig().content.deny = data['COOKIE.DENY'];
            this.consent.getConfig().content.link = data['COOKIE.LINK'];
            this.consent.getConfig().content.policy = data['COOKIE.POLICY'];

            this.consent.destroy(); // remove previous cookie bar (with default messages)
            this.consent.init(this.consent.getConfig()); // update config with translated messages
          });
      })
    );

    if (!this.consent.hasConsented()) {
      this.consent.open();
    }
  }

  changeLanguage = (): void => {
    this.langState.clearLangSelection();
  };

  setAppLanguage = (lang: TvhLanguage): void => {
    this.langState.setAppLanguage(lang);
  };

  toAddCredit = (): void => {
    this.navigate.toAddCredit();
  };

  toContactForm = (): void => {
    this.navigate.toContactForm();
  };

  toHome = (): void => {
    this.locationState.toHome();
  };

  toLocation = (): void => {
    this.navigate.toLocationSelect();
  };

  toProfile = (): void => {
    this.navigate.toProfile();
  };

  toSettings = (): void => {
    this.navigate.toManagement();
  };
}
