import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CognitoAccessToken, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { from, Observable, Observer, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { truthyFilter } from '../operators/truthy-filter';
import { AppConfigState, AppConfigStateModel } from '../store/app-config.state';
import { AuthState } from '../store/auth.state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly auth: AuthState, private readonly config: AppConfigState) {}

  intercept(req: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {
    // check if this is an API request
    if (req.url.indexOf('api') > -1) {
      // has to return an OBSERVABLE!
      return this.config.state$.pipe(
        map((state: AppConfigStateModel) => state.ready),
        // wait for AppConfigState to become ready
        truthyFilter(),
        switchMap(
          () =>
            new Observable((obs: Observer<any>) => {
              Auth.currentSession()
                .then((session: CognitoUserSession) => {
                  obs.next(session);
                  obs.complete();
                })
                .catch(() => {
                  // no-op
                });
            })
        ),
        // wait for CognitoUserSession to exist
        truthyFilter(),
        switchMap((session: CognitoUserSession) => {
          // Make sure session is set in store
          if (session && !this.auth.signedIn) {
            this.auth.updateTokens(session);
          }
          const accessTokenExpiration = session.getAccessToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);

          if (accessTokenExpiration < currentTimeSeconds) {
            // Token expired, need to refresh
            return from(Auth.currentAuthenticatedUser()).pipe(
              switchMap(
                (user: CognitoUser) =>
                  new Observable((obs: Observer<any>) => {
                    user.refreshSession(refreshToken, (err, data) => {
                      if (err) {
                        void Auth.signOut();
                        obs.error(err);
                      } else {
                        this.auth.updateTokens(session);
                        obs.next(data.getAccessToken());
                        obs.complete();
                      }
                    });
                  })
              )
            );
          }

          return of(session.getAccessToken());
        }),
        switchMap((token: CognitoAccessToken) =>
          next.handle(
            req.clone({
              setHeaders: {
                Authorization: `Bearer ${token.getJwtToken()}`,
              },
            })
          )
        )
      );
    }

    return next.handle(req);
  }
}
