import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ProfileComponent } from '../profile.component';
import { ProfileState } from '../store/profile.state';

@Injectable({
  providedIn: 'root',
})
export class ProfileCompletedGuard implements CanDeactivate<ProfileComponent> {
  constructor(
    private readonly profileState: ProfileState,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService
  ) {}

  canDeactivate(): boolean {
    // only allow the user to leave the ProfileComponent if the profile is filled in
    const profileOk = !!this.profileState.currentUserId;
    if (!profileOk) {
      this.toastr.warning(this.translate.instant('PROFILE.MSG.PROFILE_COMPLETION_REQUIRED'));
    }

    return profileOk;
  }
}
