import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { LocationState } from '../location/store/location.state';
import { AuthState } from '../shared/store/auth.state';
import { LangState } from '../shared/store/lang.state';
import { ProfileState } from './store/profile.state';

@Component({
  selector: 'tvh-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  form = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    gender: new FormControl(),
    zipCode: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    yearOfBirth: new FormControl(),
    hiddenYearOfBirth: new FormControl(),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl(''),
  });

  termsForm = new FormGroup({
    privacyTermsAccept: new FormControl(false, Validators.requiredTrue),
    termsAccept: new FormControl(false, Validators.requiredTrue),
  });

  constructor(
    private readonly auth: AuthState,
    private readonly fb: FormBuilder,
    private readonly lang: LangState,
    private readonly location: LocationState,
    readonly profile: ProfileState
  ) {}

  saveProfile = (): void => {
    this.profile.updateProfile({
      ...this.form.value,
      id: this.profile.currentUserId,
      awsCognitoSub: this.auth.idToken.payload.sub,
      language: this.lang.lang.toUpperCase(),
      termsAccepted: true,
    });
  };

  cancel = (): void => {
    this.location.toHome();
  };

  closePicker = (selectedDate: DateTime, datepicker?: MatDatepicker<any>): void => {
    if (datepicker) {
      datepicker.close();
    }
    this.form.controls.hiddenYearOfBirth.setValue(selectedDate);
    this.form.controls.yearOfBirth.setValue(selectedDate.year);
    this.form.controls.yearOfBirth.markAsDirty();
  };
}
