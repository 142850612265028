import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUtilsService } from '../../shared/services/api-utils.service';
import { AppConfigState } from '../../shared/store/app-config.state';
import { TvhUserBalance } from '../models/balance';
import { TvhUserProfile } from '../models/profile';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private readonly config: AppConfigState, private readonly http: HttpClient) {}

  getProfile(sub: string, noLoadingIndicator = false): Observable<TvhUserProfile> {
    return this.http.get<TvhUserProfile>(`${this.config.apiPath}/users/${sub}`, {
      headers: ApiUtilsService.skipErrorInterceptor(
        noLoadingIndicator ? ApiUtilsService.skipLoadingInterceptor() : undefined
      ),
    });
  }

  updateProfile(profile: TvhUserProfile): Observable<TvhUserProfile> {
    return profile.id
      ? this.http.put<TvhUserProfile>(`${this.config.apiPath}/users/${profile.id}`, profile)
      : this.http.post<TvhUserProfile>(`${this.config.apiPath}/users`, profile);
  }

  getBalance(userId: number): Observable<TvhUserBalance> {
    return this.http.get<TvhUserBalance>(`${this.config.apiPath}/users/${userId}/balance`, {
      headers: ApiUtilsService.skipLoadingInterceptor(ApiUtilsService.skipErrorInterceptor()),
    });
  }
}
